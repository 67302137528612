// change the theme
$theme-colors: (
  "primary": purple,
  "danger": #ff9900,
  "info": #FDF8F5
);

$colors: (
    "primary": purple,
    "danger": #ff9900,
    "info": #FDF8F5
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.modal-header {
    color: #FDF8F5;
    background-color: purple
}

button.close {
  color: #FDF8F5;
  background-color: purple
}

.about-appname {
  color: purple;
  font-weight: bold
}

.about-tagline {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: lighter;
  color: #ff9900
}

.about-body {
  color: darkslategray
}

.appname-color {
  color: purple
}

.measure-title {
  color: purple;
}

.instructional-text {
  color: darkslategray
}

.gettingstarted-highlight-text {
  font-family:  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: darkslategray
  
}
